.journal-content {
  justify-content: space-between;
  width: 100%;
}

.evidence-list, .ghost-options {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  width: 45%;
}

@media screen and (max-width: 1024px) {
  .evidence-list, .ghost-options {
    width: 100%;
  }
}

.evidence-list {
  margin-bottom: 2em;
}

.evidence-list h3 {
  margin-bottom: calc(0.875em - 10px);
}

@media screen and (max-width: 1024px) {
  .evidence-list {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.ghost-options-empty {
  background-image: url('../images/paper-texture.jpg');
  border-radius: 0.4em;
  box-shadow: 0.0em 0.0em 0.2em -0.1em #000000;
  display: inline-block;
  margin-bottom: 1em;
  padding: 1em;
  position: relative;
  width: 100%;
}
