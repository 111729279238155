/* Resets */
/* @import-normalize; */ /* Puts dumb margins on headings */

*, html, body, ul, ol, li, img, p, a {
  /* @include no-tap; */
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  letter-spacing: 0;
  line-height: 1;
  list-style-image: none;
  list-style-type: none;
  margin: 0;
  outline: 0;
  padding: 0;
  text-decoration: none;
}

/* a, a:link, a:visited, a:active, input, button, textarea, select {
  @include animate;
  border-radius: 0;
} */

/* button, textarea, select {
  @include browser-prefix(appearance, none);
} */

button::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner, select::-moz-focus-inner {
  border: 0;
  margin: 0;
  padding: 0;
  width: 0;
}

a > svg {
  pointer-events: none;
}

/* svg {
  fill: inherit;

  &, use {
    @include animate;
  }
} */

::selection, ::-moz-selection {
  background: #F5EBD0;
  color: #000000;
}

/* Fonts */
@font-face {
  font-family: 'Marker';
  src: url('./assets/fonts/PermanentMarker-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

/* General */
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: #000000;
  background-image: url('./assets/images/tabletop.jpg');
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  padding: 4em 5% 0;
}

@media screen and (max-width: 1024px) {
  body {
    font-size: 14px;
    padding-left: 0;
    padding-right: 0;
  }
}

h1, h2, h3 {
  display: inline-block;
  font-family: 'Marker';
  line-height: 1.7em;
  position: relative;
  width: 100%;
}

h1 {
  font-size: 3em;
}

h3 {
  font-size: 1.5em;
  margin-bottom: 0.875em;
}

p {
  display: inline-block;
  line-height: 1.4;
  margin-bottom: 0.5em;
  position: relative;
  width: 100%;
}

section {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 2em 5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
